<template>
  <ZMainPage type="card" :loading="loading">
    <div class="z-box-bg brl mb-3">
      <LineWrapper class="list1" primary labelWidth="110rem">
        <LineItem :label="item.label" :value="item.value" v-for="item in list1" :key="item.label"></LineItem>
      </LineWrapper>
    </div>
    <div class="z-box-bg brl">
      <LineWrapper class="list2" primary>
        <LineItem :label="item.label" :value="item.value" v-for="(item, index) in list2" :key="index"></LineItem>
      </LineWrapper>
    </div>
    <template #footer>
      <el-button @click="$router.back()">返回</el-button>
    </template>
  </ZMainPage>
</template>
<script>
import Dialog from "@/components/common/Dialog"
import LineWrapper from "@/components/common/LineWrapper"
import LineItem from "@/components/common/LineItem"
import { zsGet } from "@/api"
export default {
	_config:{"route":{"path":"detail","meta":{"title":"查看"}}},
  components: { Dialog, LineWrapper, LineItem },
  data() {
    return {
      loading: false,
      list1: [],
      list2: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      zsGet('/finance/fee-stu/order-detail', { id: this.$route.query.id }).then(res => {
        this.list1 = [
          { label: "学生姓名", value: res.student_name },
          { label: "学生身份证号", value: res.id_card },
          { label: "支付状态", value: res.pay_status_text },
          { label: "支付金额", value: res.money_actual },
          { label: "支付渠道", value: res.pay_mode || '-' },
          { label: "入账校区", value: res.school_name },
          { label: "支付成功时间", value: res.pay_time || '-' }
        ]
        const list = [
          { label: "收费方案", value: res.fee_plan_name },
          { label: "", value: "" },
          { label: "收费学年", value: res.school_year_name },
          { label: "生活费类型", value: res.fee_type_text },
          { label: "总金额", value: res.money_answer },
          { label: "缴费方式", value: res.fee_plan_mode },
          { label: "支付订单号", value: res.pay_number || '-'},
          { label: "支付订单创建时间", value: res.pay_created_at || '-' },
          { label: "", value: "" },
          { label: "商品订单号", value: res.order_number },
          { label: "商品订单创建时间", value: res.order_created_at },
          { label: "", value: "" },
        ]
        if(res.cancel_time) list.push({ label: "取消订单时间", value: res.cancel_time })
        this.list2 = list
      }).finally(() => this.loading = false)
    }
  }
}
</script>

<style lang="scss" scoped>
.list1 {
  margin: -10rem 0;
  display: grid;
  gap: 0 20rem;
  grid-template-columns: repeat(auto-fill, minmax(350rem, 1fr));
}
.list2 {
  display: grid;
  gap: 0 20rem;
  grid-template-columns: repeat(3, 1fr);
}
</style>
